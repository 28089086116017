.App {
  text-align: center;
}

.home p {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-logo-big {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.dropdown select {
  margin-left: 10px;
  font-size: min(15pt, 2.5vh);
  background-color: #4b4b4b;
  color: white;
  padding: 2px;
}

.dropdown option:disabled {
  font-weight: bold;
  color: #767676;
}

.checkboxes {
  padding-right: 10px;
  display: flex;
  margin-bottom: 10px;
}

.checkboxes label {
  padding-right: 30px;
  font-size: medium;
}

.checkboxes input[type=checkbox] {
  vertical-align: middle;
  margin-right: 15px;
  transform: scale(1.5);
}

.table-header {
  font-weight: bold;
  color: #61dafb;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

nav ul li {
  float: left;
}

nav ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.active-link {
  color: rgb(0, 162, 255);
}

/* Change the link color to #111 (black) on hover */
nav ul li a:hover {
  background-color: #111;
}

.homepage-link {
  color: white;
  text-decoration: underline;
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 49px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.manifest-table {
  font-size: calc(10px + 1vmin);
}

.legend-table {
  font-size: calc(10px + 1vmin);
}

.legend-table .no-border {
  border: 0px
}

.legend-table td {
  border: 2px solid grey;
}

.day-table {
  border: 3px solid #999;
}

.weekend-header {
  color: yellow
}

.sunday-table {
  border-width: 3px 3px 3px 3px;
  border-color: yellow yellow yellow #999;
  border-style: dashed dashed dashed solid;
}

.saturday-table {
  border-width: 3px 3px 3px 3px;
  border-color: yellow #999 yellow yellow;
  border-style: dashed solid dashed dashed;
}

.disabled-table {
  color: grey;
}

.ifly-login {
  text-align: right;
}

.ifly-btn {
  margin: 10px;
  border-radius: 4px;
  background: #c61018;
  color: #ffffff;
  border: 2px solid white;
  font-weight: bold;
  padding: 4px;
  cursor: pointer;
}

.email-link {
  font-size: calc(10px + 1vmin);
  padding-bottom: 1vh;
}

.email-link a {
  color: white;
  text-decoration: none;
}

.email-link a:hover {
  color: white;
  text-decoration: underline;
}