
/* reset */
button {
  all: unset;
}

.ToggleGroup {
  display: inline-flex;
  background-color: grey;
  border-radius: 4px;
  box-shadow: 0 2px 10px black;
  margin: 10px;
}

.ToggleGroupItem {
  background-color: #4b4b4b;
  color: white;
  height: 45px;
  width: min(135px, 25vw);
  display: flex;
  font-size: 15px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  user-select: none;
}
.ToggleGroupItem:first-child {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ToggleGroupItem:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ToggleGroupItem:hover {
  background-color: #6c6c6c;
}
.ToggleGroupItem[data-state='on'] {
  background-color: #61dafb;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.ToggleGroupItem:focus {
  position: relative;
  /* box-shadow: 0 0 0 2px black ;*/
}
